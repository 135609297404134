import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/components/_shared/AlertHandler.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/components/_shared/ProgressBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/Contact/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/DealSection/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/FeaturedProduct/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/Footer/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/Hero/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/MenuListMobile/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/NavigationBar/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/NutritionTable/nutritionTableNavigation/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/OrderHistory/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/OrderStatusPopup/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/RunningText/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/customers/subway-web/slices/StoreBanner/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@prismicio/react/dist/PrismicToolbar.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js")