'use client';

import { AppProgressBar } from 'next-nprogress-bar';
import dynamic from 'next/dynamic';

const ProgressBar = () => (
	<AppProgressBar height="4px" color="#FBBB00" options={{ showSpinner: false }} shallowRouting />
);

export default dynamic(() => Promise.resolve(ProgressBar), {
	ssr: false,
});
