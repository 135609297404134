'use client';

import clsx from 'clsx';
// import { useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

import { useLayout } from '../../contexts';
import { AlertType } from '../../contexts/layout';

export type EnumType = 'info' | 'danger' | 'success' | 'warning';

const AlertHandler = React.memo((): JSX.Element => {
	const { AlertValues } = useLayout();

	const show = !!AlertValues.length && AlertValues.some((data) => data.show);

	const uniqueAlerts = AlertValues.filter(
		(data, i, self) => self.findIndex((t) => t.id === data.id) === i,
	);

	return (
		<div
			className={clsx(
				'flex-cc fixed bottom-20 z-[999] max-h-10 w-full px-4 transition-all duration-200 md:bottom-28',
				show ? 'opacity-100' : 'pointer-events-none opacity-0',
			)}
		>
			<div className="flex flex-col-reverse">
				{uniqueAlerts.map((data, i) => (
					<Alert key={i} data={data} />
				))}
			</div>
		</div>
	);
});

type Alert = {
	data: AlertType;
};

function Alert({ data: { type = 'success', message, show, id } }: Alert) {
	const [hold, setHold] = useState(false);
	const [showAlert, setShowAlert] = useState(show);

	const { removeAlert, AlertValues, resetAlert } = useLayout();

	useEffect(() => {
		if (!showAlert) {
			const isAllHidden = AlertValues.every((data) => !data.show);

			if (isAllHidden) {
				resetAlert();
			}
		}
	}, [showAlert]);

	useEffect(() => {
		const line = document.getElementById('line-progress ' + id);

		line?.addEventListener('animationend', () => {
			removeAlert(id);
			setShowAlert(false);
		});

		return () => {
			line?.removeEventListener('animationend', () => {
				removeAlert(id);
				setShowAlert(false);
			});
		};
	}, [hold, removeAlert, id]);

	const theme = {
		info: 'bg-white text-textprimary shadow-sm border',
		danger: 'bg-white text-textprimary shadow-sm border',
		success: 'bg-white text-textprimary shadow-sm border',
		warning: 'bg-white text-textprimary shadow-sm border',
	};

	const lineTheme = {
		info: 'bg-primary',
		danger: 'bg-red-500',
		success: 'bg-theme-green',
		warning: 'bg-yellow',
	};

	return (
		<>
			<div
				className={clsx(
					'flex-bc animate-showup relative mb-2 overflow-hidden rounded px-3 pb-3 pt-2 transition-all duration-200',
					theme[type],
					showAlert ? 'scale-100 opacity-100' : 'pointer-events-none scale-90 opacity-0',
				)}
				style={{ zIndex: 999 }}
				onClick={() => setHold(true)}
				onMouseEnter={() => setHold(true)}
				onMouseLeave={() => setHold(false)}
			>
				<p className="text-sm">{message}</p>
				<i
					className="ml-4 cursor-pointer hover:bg-black hover:bg-opacity-10"
					onClick={() => {
						removeAlert(id);
						setShowAlert(false);
					}}
				>
					<MdClose />
				</i>

				<div className="flex-sc absolute bottom-0 right-0 h-1 w-full bg-black bg-opacity-20">
					<div
						id={'line-progress ' + id}
						className={clsx(
							lineTheme[type],
							'h-full',
							hold && 'w-full',
							showAlert && !hold && 'animate-shrink',
						)}
					></div>
				</div>
			</div>
			<style jsx>{`
				.animate-fade {
					animation-name: fade;
					animation-duration: 5s;
					animation-timing-function: ease-in;
				}
				@keyframes fade {
					0% {
						opacity: 1;
					}
					85% {
						opacity: 1;
					}
					100% {
						opacity: 0;
					}
				}
				.animate-shrink {
					animation-name: shrink;
					animation-duration: 5s;
					animation-timing-function: ease-in;
				}
				@keyframes shrink {
					from {
						width: 100%;
					}
					to {
						width: 0%;
					}
				}

				@keyframes showup {
					0% {
						opacity: 0;
						transform: scale(0.9);
					}
					100% {
						opacity: 1;
						transform: scale(1);
					}
				}

				.animate-showup {
					animation-name: showup;
					animation-duration: 0.2s;
					animation-timing-function: ease-in-out;
				}
			`}</style>
		</>
	);
}

export default AlertHandler;
